var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "page-container",
      style: "background-color:" + _vm.$options.configs.backgroundColor + ";"
    },
    [
      _c("page-header", {
        attrs: {
          title:
            _vm.$options.configs.title[
              Object.keys(_vm.$options.configs.title)[0]
            ].title,
          "header-class": "homepage",
          subtitle:
            _vm.$options.configs.subtitle[
              Object.keys(_vm.$options.configs.subtitle)[0]
            ].subtitle,
          "bg-url":
            _vm.$options.configs.title[
              Object.keys(_vm.$options.configs.title)[0]
            ].titleImg,
          "bg-position": "left top",
          "bg-size": "contain",
          "title-color":
            _vm.$options.configs.title[
              Object.keys(_vm.$options.configs.title)[0]
            ].titleColor,
          "subtitle-color":
            _vm.$options.configs.subtitle[
              Object.keys(_vm.$options.configs.subtitle)[0]
            ].subtitleColor,
          "extra-subtitle": _vm.$options.configs.extraSubtitle
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "content home" }, [
        _c("div", { staticClass: "content-outer" }, [
          _c("div", { staticClass: "content-inner" }, [
            _c("div", { staticClass: "banners" }, [
              _c("p", {
                staticClass: "banners",
                domProps: { innerHTML: _vm._s(_vm.$options.configs.content) }
              })
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "end-image-container bg-img",
                style:
                  "background-image:url('" +
                  _vm.$options.configs.endImage +
                  "');background-position: left center;"
              },
              [
                _c(
                  "div",
                  { staticClass: "content-actions d-flex" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "flex-grow-shrink-0 mr-2",
                        staticStyle: { "font-size": "80%" },
                        style:
                          "color:" +
                          _vm.$options.configs.buttonColor +
                          ";border-color:" +
                          _vm.$options.configs.buttonColor,
                        attrs: {
                          type: "button",
                          to: { name: "bases" },
                          squared: "",
                          variant: "outline-success"
                        }
                      },
                      [
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c("div", { staticClass: "pt-2" }, [
                            _c("strong", [_vm._v("BASES DEL CONCURSO")])
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "font-size": "200%" } },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "file-alt" }
                              })
                            ],
                            1
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-toggle",
                            rawName: "v-b-toggle.sidebar-menu",
                            modifiers: { "sidebar-menu": true }
                          }
                        ],
                        staticClass: "flex-grow-shrink-0",
                        staticStyle: { "font-size": "80%" },
                        style:
                          "color:" +
                          _vm.$options.configs.buttonColor +
                          ";border-color:" +
                          _vm.$options.configs.buttonColor,
                        attrs: { squared: "", variant: "outline-success" }
                      },
                      [
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c("div", { staticClass: "pt-2" }, [
                            _c("strong", [_vm._v("EXPLORA EL MENÚ")])
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "font-size": "200%" } },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "compass" }
                              })
                            ],
                            1
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-modal",
                            rawName: "v-b-modal",
                            value: _vm.$options.user.isLogged
                              ? ""
                              : "login-modal",
                            expression:
                              "$options.user.isLogged ? '' : 'login-modal'"
                          }
                        ],
                        staticClass: "flex-grow-shrink-0 ml-2",
                        staticStyle: { "font-size": "80%" },
                        style:
                          "color:" +
                          _vm.$options.configs.buttonColor +
                          ";border-color:" +
                          _vm.$options.configs.buttonColor,
                        attrs: { squared: "", variant: "outline-success" },
                        on: {
                          click: function($event) {
                            _vm.$options.user && _vm.$options.user.isLogged
                              ? _vm.$router.push({ name: "uploadDrawing" })
                              : null
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c("div", { staticClass: "pt-2" }, [
                            _c("strong", [_vm._v("PARTICIPAR")])
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "font-size": "200%" } },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "vote-yea" }
                              })
                            ],
                            1
                          )
                        ])
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "logos-container" },
        _vm._l(_vm.$options.configs.logos, function(logo, index) {
          return _c(
            "a",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: index <= 5 && logo.logo && logo.logo != "",
                  expression: "index <= 5 && logo.logo && logo.logo != ''"
                }
              ],
              key: index,
              staticClass: "logo-item",
              attrs: { href: logo.link, target: "_blank" }
            },
            [
              _c("div", {
                staticClass: "bg-img",
                style:
                  "background-image:url('" +
                  logo.logo +
                  "');background-position: center;width: 100%;height: 100%;"
              })
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "logos-container" },
        _vm._l(_vm.$options.configs.logos, function(logo, index) {
          return _c(
            "a",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: index > 5 && logo.logo && logo.logo != "",
                  expression: "index > 5 && logo.logo && logo.logo != ''"
                }
              ],
              key: index,
              staticClass: "logo-item",
              attrs: { href: logo.link, target: "_blank" }
            },
            [
              _c("div", {
                staticClass: "bg-img",
                style:
                  "background-image:url('" +
                  logo.logo +
                  "');background-position: center;width: 100%;height: 100%;"
              })
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("page-footer"),
      _vm._v(" "),
      _c("success-modal", {
        attrs: {
          content: "¡Tu correo ha sido validado!",
          id: "verification_success_modal"
        },
        on: {
          click: function($event) {
            return _vm.closeModal("verification_success_modal")
          }
        }
      }),
      _vm._v(" "),
      _c("error-modal", {
        attrs: { content: _vm.errorMsg, id: "verification_error_modal" },
        on: {
          click: function($event) {
            return _vm.closeModal("verification_error_modal")
          }
        }
      }),
      _vm._v(" "),
      _c("reset-password-modal", {
        attrs: { "reset-token": _vm.passwordResetToken }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }