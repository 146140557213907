<style lang="scss" scoped>
</style>

<template>
  <footer class="">
    <div class="footer-container">
      <div class="copyright">© {{currentYear}} Nuestra Naturaleza</div>
      <div class="credits">
        <div class="bg-img signature-img mr-3" :style="
              'background-image:url(\'' +
                assets.footerImgUrl +
                '\');'
            ">
        </div>
        <div>Ilustraciones de Sergio, Andrés, Aluna y Maia</div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "PageFooter",
  props: {
  },
  data() {
    return {
      assets: {
        footerImgUrl: ""
      },
      currentYear: 2021
    };
  },
  mounted() {
    this.currentYear = new Date().getFullYear();
    this.getAssets();
  },
  methods: {
    getAssets() {
      this.assets = window.assets.footer;
    },
  },
};
</script>
