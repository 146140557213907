<style lang="scss" scoped>
$desktop-med: 1441px;
$tablet: 1025px;
$mobile: 767px;
.content {
  &.home {
    font-size: 1.2em;
    padding-bottom: 0px;
    @media only screen and (max-width: $desktop-med) {
      font-size: 0.75em;
    }
    @media only screen and (max-width: $tablet) {
      font-size: 0.6em;
    }
    @media only screen and (max-width: $mobile) {
      font-size: 0.6em;
    }
    .content-outer {
      width: 100%;
      padding: 0px 10em;
      @media only screen and (max-width: $tablet) {
        padding-left: 5em;
        padding-right: 5em;
      }
      @media only screen and (max-width: $mobile) {
        padding-right: 0em;
        padding-left: 0em;
      }
      .content-inner {
        // width: 66%;
        // padding-right: 20%;
        font-size: 160%;
        @media only screen and (max-width: $mobile) {
          padding: 0em 1em;
          width: 100%;
          padding-bottom: 3em;
          // background-color: #ffffffba;
        }
        .charter-roman {
          font-family: "CharterRoman";
        }
        .banners {
          .title {
            font-size: 250%;
            line-height: 120%;
            text-align: center;
            margin-bottom: 1.5em;
          }
          p {
            font-family: "MoonFlower";
            line-height: 130%;
            font-size: 200%;
          }
        }
        .end-image-container {
          .content-actions {
            width: 60%;
            margin-left: auto;
            padding: 10em 0em 0em;
            @media only screen and (max-width: $tablet) {
              width: 100%;
            }
            @media only screen and (max-width: $mobile) {
            padding: 20em 0em 0em;
            }
          }
        }
      }
    }
  }
}

.logos-container {
  width: 100%;
  display: flex;
  padding: 1em 10em 0px;
  background-color: white;
  margin-top: 5em;
  @media only screen and (max-width: $tablet) {
    padding-left: 5em;
    padding-right: 5em;
  }
  @media only screen and (max-width: $mobile) {
    padding-right: 0em;
    padding-left: 0em;
  }
  .logo-item {
    flex: 1 1 auto;
    height: 10em;
  }
}

.btn:hover,
.btn:active,
.btn:focus {
  color: unset;
  background-color: unset !important;
  text-decoration: none !important;
  box-shadow: none !important;
}
</style>

<template>
  <div
    class="page-container"
    :style="`background-color:${$options.configs.backgroundColor};`"
  >
    <page-header
      :title="
        $options.configs.title[Object.keys($options.configs.title)[0]].title
      "
      header-class="homepage"
      :subtitle="
        $options.configs.subtitle[Object.keys($options.configs.subtitle)[0]]
          .subtitle
      "
      :bg-url="
        $options.configs.title[Object.keys($options.configs.title)[0]].titleImg
      "
      bg-position="left top"
      bg-size="contain"
      :title-color="
        $options.configs.title[Object.keys($options.configs.title)[0]]
          .titleColor
      "
      :subtitle-color="
        $options.configs.subtitle[Object.keys($options.configs.subtitle)[0]]
          .subtitleColor
      "
      :extra-subtitle="$options.configs.extraSubtitle"
    />
    <div class="content home">
      <div class="content-outer">
        <div class="content-inner">
          <div class="banners">
            <!-- <div
            class="banners title charter-roman"
            v-html="$options.configs.contentTitle"
          > -->
            <!-- {{$options.configs.contentTitle}} -->
            <!-- </div> -->
            <p class="banners" v-html="$options.configs.content">
              <!-- {{$options.configs.content}} -->
            </p>
          </div>
          <div
            class="end-image-container bg-img"
            :style="
              'background-image:url(\'' +
                $options.configs.endImage +
                '\');background-position: left center;'
            "
          >
            <div class="content-actions d-flex">
              <b-button
                type="button"
                :to="{ name: 'bases' }"
                squared
                variant="outline-success"
                class="flex-grow-shrink-0 mr-2"
                style="font-size: 80%"
                :style="
                  `color:${$options.configs.buttonColor};border-color:${$options.configs.buttonColor}`
                "
              >
                <div class="d-flex flex-column">
                  <div class="pt-2">
                    <strong>BASES DEL CONCURSO</strong>
                  </div>
                  <div style="font-size: 200%">
                    <font-awesome-icon icon="file-alt"></font-awesome-icon>
                  </div>
                </div>
              </b-button>
              <b-button
                v-b-toggle.sidebar-menu
                squared
                variant="outline-success"
                class="flex-grow-shrink-0"
                style="font-size: 80%"
                :style="
                  `color:${$options.configs.buttonColor};border-color:${$options.configs.buttonColor}`
                "
              >
                <div class="d-flex flex-column">
                  <div class="pt-2">
                    <strong>EXPLORA EL MENÚ</strong>
                  </div>
                  <div style="font-size: 200%">
                    <font-awesome-icon icon="compass"></font-awesome-icon>
                  </div>
                </div>
              </b-button>
              <b-button
                @click="
                  $options.user && $options.user.isLogged
                    ? $router.push({ name: 'uploadDrawing' })
                    : null
                "
                v-b-modal="$options.user.isLogged ? '' : 'login-modal'"
                squared
                variant="outline-success"
                class="flex-grow-shrink-0 ml-2"
                style="font-size: 80%"
                :style="
                  `color:${$options.configs.buttonColor};border-color:${$options.configs.buttonColor}`
                "
              >
                <div class="d-flex flex-column">
                  <div class="pt-2">
                    <strong>PARTICIPAR</strong>
                  </div>
                  <div style="font-size: 200%">
                    <font-awesome-icon icon="vote-yea"></font-awesome-icon>
                  </div>
                </div>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="logos-container">
      <!-- <div
            class="logo-banner bg-img"
            :style="
              'background-image:url(\'' +
                assets.logosBannerUrl +
                '\');background-position: left center;'
            "
          ></div> -->
      <a
        :href="logo.link"
        v-for="(logo, index) in $options.configs.logos"
        :key="index"
        class="logo-item"
        target="_blank"
        v-show="index <= 5 && logo.logo && logo.logo != ''"
        ><div
          class="bg-img"
          :style="
            'background-image:url(\'' +
              logo.logo +
              '\');background-position: center;width: 100%;height: 100%;'
          "
        ></div
      ></a>
    </div>
    <div class="logos-container">
      <a
        :href="logo.link"
        v-for="(logo, index) in $options.configs.logos"
        :key="index"
        class="logo-item"
        target="_blank"
        v-show="index > 5 && logo.logo && logo.logo != ''"
        ><div
          class="bg-img"
          :style="
            'background-image:url(\'' +
              logo.logo +
              '\');background-position: center;width: 100%;height: 100%;'
          "
        ></div
      ></a>
    </div>
    <page-footer></page-footer>
    <success-modal
      content="¡Tu correo ha sido validado!"
      @click="closeModal('verification_success_modal')"
      id="verification_success_modal"
    ></success-modal>
    <error-modal
      :content="errorMsg"
      @click="closeModal('verification_error_modal')"
      id="verification_error_modal"
    ></error-modal>
    <reset-password-modal
      :reset-token="passwordResetToken"
    ></reset-password-modal>
  </div>
</template>

<script>
import PageHeader from "../components/PageHeader";
import PageFooter from "../components/PageFooter.vue";
import ErrorModal from "../components/modals/ErrorModal.vue";
import SuccessModal from "../components/modals/SuccessModal.vue";
import ResetPasswordModal from "../components/ResetPasswordModal.vue";

export default {
  components: {
    PageHeader,
    PageFooter,
    ErrorModal,
    SuccessModal,
    ResetPasswordModal,
  },
  name: "Home",
  user: window.configs.user,
  configs: window.configs.home,
  data() {
    return {
      assets: {
        headerBgUrl: "",
        contentBgUrl: "",
        logosBannerUrl: "",
      },
      // configs: {
      //   titleColor: "",
      //   subtitleColor: "",
      //   contentTitleColor: ""
      // },
      errorMsg: "",
      passwordResetToken: "",
    };
  },
  mounted() {
    // console.log(this.$options.configs);
    this.getAssets();
    // this.getConfigs();
    let routeQuery = this.$route.query;
    // console.log(routeQuery);
    if (routeQuery.verification) {
      this.$router.replace("/");
      switch (routeQuery.verification) {
        case "invalid_token":
          this.$bvModal.show("verification_error_modal");
          this.errorMsg = "El token es inválido.";
          break;
        case "expired":
          this.$bvModal.show("login-modal");
          this.$bvModal.show("verification_error_modal");
          this.errorMsg =
            "Tu link ha expirado, intenta ingresar para generar otro.";
          break;
        case "success":
          this.$bvModal.show("login-modal");
          this.$bvModal.show("verification_success_modal");
          break;

        default:
          break;
      }
    }
    if (routeQuery.resetPassword) {
      this.$router.replace("/");
      switch (routeQuery.resetPassword) {
        case "invalid_token":
          this.$bvModal.show("verification_error_modal");
          this.errorMsg = "El token es inválido.";
          break;
        case "expired":
          this.$bvModal.show("verification_error_modal");
          this.errorMsg =
            "Tu link ha expirado, intenta ingresar para generar otro.";
          break;
        case "success":
          this.passwordResetToken = routeQuery.token;
          this.$bvModal.show("reset-password-modal");
          break;

        default:
          break;
      }
    }
    if (routeQuery.requireAuth) {
      this.$router.replace("/");
      this.$bvModal.show("login-modal");
    }
  },
  methods: {
    getAssets() {
      this.assets = window.assets.home;
    },
    // getConfigs() {
    //   this.configs = window.configs.home;
    // },
    closeModal(id) {
      this.$bvModal.hide(id);
    },
  },
};
</script>
